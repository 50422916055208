import { template as template_189b1e11c1d542eab88a53539e82c4ba } from "@ember/template-compiler";
const FKLabel = template_189b1e11c1d542eab88a53539e82c4ba(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
