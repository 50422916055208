import { template as template_b30d5eb58dd243feb6028a7a59bd3606 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_b30d5eb58dd243feb6028a7a59bd3606(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
